var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-view',{attrs:{"title":"Статистика","filter":"","store-module":"stats","headers":[
        { text: 'Дата', value: 'created_at_datetime', width: 1 },
        { text: 'Платформа', value: 'platform_formatted', width: 1, sortable: false },
        { text: 'Версия', value: 'version' },
        { text: 'IP-адрес', value: 'ip' },
        { text: 'Код страны', value: 'country', width: 1 },
        { text: 'Населенный пункт', value: 'city', width: 1 },
        { text: 'Широта', value: 'latitude' },
        { text: 'Долгота', value: 'longitude' }
    ]},scopedSlots:_vm._u([{key:"filter",fn:function(ref){
    var filter = ref.filter;
return [_c('v-row',[_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-select',{attrs:{"dense":"","color":_vm.$const.color.primary,"items":[{
                    text: 'Все',
                    value: null
                  },{
                    text: 'Windows',
                    value: 'windows'
                  },{
                    text: 'MacOS',
                    value: 'macos'
                  },{
                    text: 'Linux',
                    value: 'linux'
                  },{
                    text: 'Android',
                    value: 'android'
                  },{
                    text: 'iOS',
                    value: 'ios'
                  }],"label":"Платформа"},model:{value:(filter.platform),callback:function ($$v) {_vm.$set(filter, "platform", $$v)},expression:"filter.platform"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","autocomplete":"new-email","label":"Версия","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.version),callback:function ($$v) {_vm.$set(filter, "version", $$v)},expression:"filter.version"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","autocomplete":"new-email","label":"ID устройства","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.huid),callback:function ($$v) {_vm.$set(filter, "huid", $$v)},expression:"filter.huid"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","autocomplete":"new-email","label":"IP-адрес","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.ip),callback:function ($$v) {_vm.$set(filter, "ip", $$v)},expression:"filter.ip"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","autocomplete":"new-email","label":"Код страны","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.country),callback:function ($$v) {_vm.$set(filter, "country", $$v)},expression:"filter.country"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","autocomplete":"new-email","label":"Населенный пункт","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.city),callback:function ($$v) {_vm.$set(filter, "city", $$v)},expression:"filter.city"}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }