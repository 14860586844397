<template>
    <grid-view 
        title="Статистика"
        filter
        store-module="stats"
        :headers="[
            { text: 'Дата', value: 'created_at_datetime', width: 1 },
            { text: 'Платформа', value: 'platform_formatted', width: 1, sortable: false },
            { text: 'Версия', value: 'version' },
            { text: 'IP-адрес', value: 'ip' },
            { text: 'Код страны', value: 'country', width: 1 },
            { text: 'Населенный пункт', value: 'city', width: 1 },
            { text: 'Широта', value: 'latitude' },
            { text: 'Долгота', value: 'longitude' }
        ]"
    >
        <!-- Filter -->
        <template v-slot:filter="{ filter }">
            <v-row>
                <v-col lg="6" sm="12">
                    <v-select
                      dense
                      v-model="filter.platform"
                      :color="$const.color.primary"
                      :items="[{
                        text: 'Все',
                        value: null
                      },{
                        text: 'Windows',
                        value: 'windows'
                      },{
                        text: 'MacOS',
                        value: 'macos'
                      },{
                        text: 'Linux',
                        value: 'linux'
                      },{
                        text: 'Android',
                        value: 'android'
                      },{
                        text: 'iOS',
                        value: 'ios'
                      }]"
                      label="Платформа"
                    ></v-select>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        dense
                        v-model="filter.version"
                        clearable
                        autocomplete="new-email"
                        label="Версия" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary"
                    ></v-text-field>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        dense
                        v-model="filter.huid"
                        clearable
                        autocomplete="new-email"
                        label="ID устройства" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary"
                    ></v-text-field>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        dense
                        v-model="filter.ip"
                        clearable
                        autocomplete="new-email"
                        label="IP-адрес" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary"
                    ></v-text-field>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        dense
                        v-model="filter.country"
                        clearable
                        autocomplete="new-email"
                        label="Код страны" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary"
                    ></v-text-field>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        dense
                        v-model="filter.city"
                        clearable
                        autocomplete="new-email"
                        label="Населенный пункт" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary"
                    ></v-text-field>
                </v-col>
            </v-row>
        </template>
        <!-- END Filter -->
    </grid-view>
</template>
<script>
import GridView from '@/components/crud/GridView.vue'
export default {
    name: 'UserIndex',
    components: { GridView }, 
    data() {
        return {}
    }
}
</script>